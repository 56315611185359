import classNames from "classnames"
import React, { useState } from "react"

import s from "./VideoBehindPlaceholder.module.scss"
import VideoPlaceholder from "./VideoPlaceholder"
import YoutubePlayer from "./YoutubePlayer"

export default function VideoBehindPlaceholder({
  className,
  videoId,
  imageUrl,
}) {
  const [videoVisible, setVideoVisible] = useState(false)

  return (
    <div className={classNames(s.videoBehindPlaceholder, className)}>
      {videoVisible && videoId ? (
        <YoutubePlayer videoId={videoId} />
      ) : (
        <VideoPlaceholder
          imageUrl={imageUrl}
          onClick={() => setVideoVisible(true)}
          playButtonVisible={!!videoId}
        />
      )}
    </div>
  )
}
