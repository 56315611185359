import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { CheckMarkIcon, DropdownArrowIcon } from "../common/Icons"

import s from "./Form.module.scss"

export default function Select({
  className,
  errorMessage,
  label,
  name,
  onChange,
  selectOptions,
  type = "text",
  value,
}) {
  const id = `field-${name}`
  const [currentValue, setCurrentValue] = useState(selectOptions[0])
  const [selectedValue, setSelectedValue] = useState("")
  const [open, setOpen] = useState(false)
  const hiddenInput = useRef(null)

  const handleChange = (value) => {
    setCurrentValue(value)
    setSelectedValue(value)
    setOpen(!open)
  }

  useEffect(() => {
    const event = new Event("input", { bubbles: true })

    hiddenInput.current.dispatchEvent(event)
    hiddenInput.current._valueTracker.getValue = () => {
      return "fake value to trigger inputs onchange"
    }
  }, [currentValue])

  return (
    <div
      className={classNames(s.formField, className, {
        [s.error]: errorMessage,
      })}
    >
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        name={name}
        onChange={onChange}
        ref={hiddenInput}
        style={{ display: "none" }}
        type={type}
        value={currentValue}
      />
      <span className={s.customSelect} onClick={() => setOpen(!open)}>
        {currentValue}
        <DropdownArrowIcon
          className={open ? s.dropdownArrowUp : s.dropdownArrowDown}
        />
      </span>
      {open && (
        <ul className={s.selectOptions}>
          {selectOptions.map((item, index) => {
            return (
              <li
                className={s.selectOption}
                key={index}
                onClick={index === 0 ? null : () => handleChange(item)}
              >
                {item}
                {selectedValue === item ? (
                  <CheckMarkIcon className={s.checkMarkIcon} />
                ) : null}
              </li>
            )
          })}
        </ul>
      )}

      {errorMessage ? (
        <span className={s.errorLabel}>{errorMessage}</span>
      ) : null}
    </div>
  )
}
