import classNames from "classnames"
import React from "react"

import { PlayIcon } from "../common/Icons"
import s from "./VideoPlaceholder.module.scss"

export default function VideoPlaceholder({
  imageUrl,
  onClick,
  playButtonVisible,
}) {
  return (
    <div
      className={classNames(s.placeholder, {
        [s.disabled]: !playButtonVisible,
      })}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      style={{ backgroundImage: `url(${imageUrl})` }}
      tabIndex={0}
    >
      {playButtonVisible ? <PlayIcon className={s.playButton} /> : null}
    </div>
  )
}
