import { graphql } from "gatsby"
import { navigate } from "gatsby"
import React from "react"
import FormCareers from "../components/FormCareers/FormCareers"

import RichText from "../components/common/RichText"
import Hero, {
  colors,
  variations as heroVariations,
  titleColors,
} from "../components/Hero/Hero"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import VideoBehindPlaceholder from "../components/VideoPlaceholder/VideoBehindPlaceholder"
import {
  selectCareersPageContent,
  selectProductGroups,
  selectTranslations,
} from "../graphql/selectors"
import { sendCareersForm } from "../service/rest"
import { createLocalizedPagePath } from "../utils/urls"
import s from "./careers.module.scss"
import classNames from "classnames"
import { CheckMarkIconNew } from "../components/common/Icons"
import Img from "../components/common/Img"

const videoPlaceholderImage = "/images/pages/career/video-placeholder.png"

export default function CareersPage(props) {
  const { data, pageContext } = props
  const { langCode, locale, localePath, pagePathsByLangCode } = pageContext
  const translations = selectTranslations(data)
  const {
    descriptions,
    form,
    hero,
    seo,
    vacancies,
    youtubeVideoId,
  } = selectCareersPageContent(data)

  return (
    <Layout
      className={s.careersPage}
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
    >
      <SEO {...seo} lang={langCode} />
      {locale.endsWith("EE") ? (
        <div className={s.heroWrapper}>
          <h1 className={s.heroHeading}>{hero.title}</h1>
          <div
            className={
              locale.includes("et")
                ? s.heroWrapperImgCola
                : s.heroWrapperImgNuggets
            }
          >
            <Img
              alt={hero.imagesData[0].alt}
              placeholderHidden
              src={hero.imagesData[0].desktop}
            />
          </div>
        </div>
      ) : (
        <Hero
          careers
          color={colors.grenadier}
          description={hero.description}
          locale={locale}
          title={hero.title}
          titleColor={titleColors.bright}
          variation={heroVariations.compact}
        />
      )}
      {locale.endsWith("EE") ? (
        <section
          className={classNames(s.descriptions, s.descriptionsEstoniaMarket)}
          id="descriptions-estonia"
        >
          <h2>{descriptions[0]}</h2>
          <ul>
            {descriptions.map((item, index, descriptions) => {
              if (index !== 0 && index + 1 !== descriptions.length) {
                return (
                  <li key={index}>
                    <CheckMarkIconNew />
                    <span>{item}</span>
                  </li>
                )
              }

              return null
            })}
          </ul>
        </section>
      ) : (
        <section className={s.descriptions}>
          {descriptions.map((description, index) => (
            <p className={s.description} key={index}>
              {description}
            </p>
          ))}
        </section>
      )}
      {locale.endsWith("EE") && (
        <div className={s.container}>
          <div className={s.formHolder}>
            <h2>{descriptions[5]}</h2>
            <FormCareers
              agreementOptions={form.agreementOptions}
              emailLabel={form.emailLabel}
              fileInputText={form.fileInputText}
              fileLabel={form.fileLabel}
              langCode={langCode}
              locale={locale}
              messageLabel={form.messageLabel}
              nameLabel={form.nameLabel}
              onSubmit={handleFormSubmit}
              phoneNumberLabel={form.phoneNumberLabel}
              selectLabel={form.selectLabel}
              selectOptions={form.selectOptions}
              sendingLabel={form.sendingLabel}
              submitLabel={form.submitLabel}
            />
          </div>
        </div>
      )}

      {locale.endsWith("EE") ? (
        <section className={s.contentEstoniaMarket}>
          <div className={s.flexContainer}>
            <p>
              {form.footerText[0]}{" "}
              <a
                href={form.footerText[5]}
                rel="noopener noreferrer"
                target="_blank"
              >
                {form.footerText[1]}
              </a>
            </p>
          </div>
          <p>{form.footerText[2]}</p>
          <div className={s.flexContainer}>
            <p>
              {form.footerText[3]}{" "}
              <a href={`mailto:${form.footerText[4]}`}>{form.footerText[4]}</a>
            </p>
          </div>
        </section>
      ) : (
        <section className={s.content}>
          <div className={s.row}>
            <section className={s.videoSection}>
              <VideoBehindPlaceholder
                className={s.video}
                imageUrl={videoPlaceholderImage}
                videoId={youtubeVideoId}
              />
            </section>
            <section className={s.vacancies}>
              <h2>
                <RichText>{vacancies.title}</RichText>
              </h2>
              {vacancies.vacancies.map((vacancy) => (
                <Vacancy key={vacancy.title} {...vacancy} />
              ))}
            </section>
          </div>
        </section>
      )}
    </Layout>
  )

  function handleFormSubmit(formData) {
    sendCareersForm(formData).then(redirectToSuccessPage, redirectToSuccessPage)
  }

  function redirectToSuccessPage() {
    navigate(
      createLocalizedPagePath(
        translations.pages,
        localePath,
        "/careers-success/"
      )
    )
  }
}

export const query = graphql`
  query CareersPage($locale: String) {
    ...LayoutFragment
    ...CareersPageFragment
    ...TranslationsFragment
  }
`

function Vacancy({ details, title, url, urlText }) {
  return (
    <div className={s.vacancy}>
      <h3>{title}</h3>
      <p dangerouslySetInnerHTML={{ __html: details }} />
      <a href={url} rel="noopener noreferrer" target="_blank">
        {urlText}
      </a>
    </div>
  )
}
