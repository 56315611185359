import React, { useState } from "react"

import { validatorTypes } from "../../hooks/forms/forms"
import useCaptcha from "../../hooks/forms/useCaptcha"
import useInput from "../../hooks/forms/useInput"
import Captcha from "../Form/Captcha"
import Checkbox from "../Form/Checkbox"
import Form from "../Form/Form"
import Input from "../Form/Input"
import Select from "../Form/Select"
import Submit from "../Form/Submit"
import TextArea from "../Form/TextArea"
import s from "./FormCareers.module.scss"

export default function FormCareers({
  agreementOptions,
  captchaDisabled,
  emailLabel,
  fileInputText,
  fileLabel,
  langCode,
  locale,
  messageLabel,
  nameLabel,
  onSubmit,
  phoneNumberLabel,
  selectLabel,
  selectOptions,
  sendingLabel,
  submitLabel,
}) {
  const [loading, setLoading] = useState(false)
  const [errorsVisible, setErrorsVisible] = useState(false)
  const captcha = useCaptcha()
  const fields = {
    name: useInput({ initialValue: "", errorsVisible, required: true }),
    email: useInput({
      initialValue: "",
      errorsVisible,
      required: true,
      validatorType: validatorTypes.email,
    }),
    message: useInput({ initialValue: "", errorsVisible }),
    phoneNumber: useInput({
      initialValue: "",
      errorsVisible,
      required: true,
      validatorType: validatorTypes.phoneNumber,
    }),
    select: useInput({ errorsVisible, required: true }),
    file: useInput({ type: "file", errorsVisible }),
    checkboxFirst: useInput({
      type: "checkbox",
      errorsVisible,
      required: true,
    }),
    checkboxSecond: useInput({
      type: "checkbox",
      errorsVisible,
      required: true,
    }),
    captcha: captchaDisabled ? null : captcha,
  }

  return (
    <Form className={s.form} id="form-careers" onSubmit={handleSubmit}>
      <Input
        className={s.name}
        label={nameLabel}
        name="name"
        {...fields.name.props}
      />
      <Input
        className={s.email}
        label={emailLabel}
        name="email"
        {...fields.email.props}
      />
      <Input
        className={s.phoneNumber}
        label={phoneNumberLabel}
        name="phoneNumber"
        type="tel"
        {...fields.phoneNumber.props}
      />
      <Select
        label={selectLabel}
        name="select"
        selectOptions={selectOptions}
        {...fields.select.props}
      />
      <Input
        className={s.phoneNumber}
        fileInputText={fileInputText}
        label={fileLabel}
        name="file"
        type="file"
        {...fields.file.props}
      />
      <TextArea
        className={s.message}
        label={messageLabel}
        name="message"
        {...fields.message.props}
      />
      <Checkbox name="checkbox-1" {...fields.checkboxFirst.props}>
        <>
          {agreementOptions[0]}{" "}
          <a
            href={agreementOptions[3]}
            rel="noopener noreferrer"
            target="_blank"
          >
            {agreementOptions[1]}
          </a>
        </>
      </Checkbox>
      <Checkbox name="checkbox-2" {...fields.checkboxSecond.props}>
        {agreementOptions[2]}
      </Checkbox>
      {captchaDisabled ? null : (
        <Captcha
          className={s.captcha}
          {...fields.captcha.props}
          langCode={langCode}
        />
      )}
      <Submit
        className={s.submit}
        disabled={loading}
        value={loading ? sendingLabel : submitLabel}
      />
    </Form>
  )

  function handleSubmit(event) {
    event.preventDefault()
    if (isFormValid()) {
      submitForm()
    } else {
      setErrorsVisible(true)
    }
  }

  function submitForm() {
    const now = Date.now()
    const data = new FormData()

    data.append("name", fields.name.value)
    data.append("email", fields.email.value)
    data.append("message", fields.message.value)
    data.append("locale", locale)
    data.append("timestamp", new Date(now).toISOString())
    data.append("phoneNumber", fields.phoneNumber.value)
    data.append("location", fields.select.value)
    data.append("file", fields.file.file)
    data.append("termsAndConditions", fields.checkboxFirst.value)
    data.append("personalData", fields.checkboxSecond.value)

    setLoading(true)
    resetForm()
    onSubmit(data)
  }

  function isFormValid() {
    return Object.values(fields)
      .filter((field) => field !== null)
      .every((field) => field.valid && field)
  }

  function resetForm() {
    Object.values(fields)
      .filter((field) => field !== null)
      .forEach((field) => field.reset && field.reset())
  }
}
