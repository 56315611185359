import React from "react"

export default function YoutubePlayer({ videoId }) {
  return (
    <iframe
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      frameBorder="0"
      src={`https://www.youtube.com/embed/${videoId}?autoplay=true`}
      title="youtube"
    />
  )
}
