import classNames from "classnames"
import React, { useState } from "react"
import { CheckMarkIcon } from "../common/Icons"

import s from "./Form.module.scss"

export default function Checkbox({
  children,
  className,
  errorMessage,
  name,
  onChange,
  type = "checkbox",
  value,
}) {
  const [checked, setChecked] = useState(false)

  const handleClick = (event) => {
    onChange(event)
    setChecked(!checked)
  }

  return (
    <div
      className={classNames(s.formField, className, {
        [s.error]: errorMessage,
      })}
    >
      <label className={s.customCheckboxContainer}>
        <input
          name={name}
          onChange={handleClick}
          style={{ display: "none" }}
          type={type}
          value={value}
        />
        <div
          className={
            errorMessage && !checked
              ? classNames(s.customCheckboxInput, s.checkboxError)
              : checked
              ? classNames(s.customCheckboxInput, s.customCheckboxInputChecked)
              : s.customCheckboxInput
          }
        >
          {checked && <CheckMarkIcon />}
        </div>
        <p>{children}</p>
      </label>
    </div>
  )
}
